import React from 'react'

//import classnames from 'classnames';
import classes from '../styles/teachers.module.sass'
import { teacherData } from '../../course_content/meta/TeacherData'
import { alumniData } from '../../course_content/meta/AlumniData'

export const Teachers = () => {

    return (
        <div className={classes.container}>
            <h1>Teachers on the course</h1>
            <div className={classes.teachercards}>
                {teacherData.map((item, index) => (
                    <div className={classes.teacher}>
                        <img src={item.imgpath} alt={item.jobtitle} className={classes.teacherimg}></img>
                        <p><b>{item.name}</b></p>
                        <p><i>{item.jobtitle}</i></p>
                    </div>
                ))}
            </div>
		<h2>Alumni teaching assistants</h2>
		<div className={classes.teachercards}>
                {alumniData.map((item, index) => (
                    <div className={classes.teacher}>
                        <img src={item.imgpath} alt={item.jobtitle} className={classes.alumniimg}></img>
                        <p><b>{item.name}</b></p>
                    </div>
                ))}
            </div>

        </div>
    )
}

export default Teachers

