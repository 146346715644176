import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

//import classnames from 'classnames';
import classes from '../styles/hero.module.sass'


const Hero = () => {
    
    const query = graphql`{
        site {
            siteMetadata {
                title
                description
                registrationUrl
            }
        }
    }`;


    return (

        <StaticQuery
            query={query}
            render={data => {
                const meta = data.site.siteMetadata
                return (
                <>
                    <div className={classes.container}>
                        <div className={classes.background}>
                        </div>
                        <div className={classes.content}>
                            <div className={classes.items}>
                                <h1 className={classes.title}>
                                    {meta.title}
                                </h1>
                                <p className={classes.infotext}
                                    dangerouslySetInnerHTML={{__html: meta.description}}>
                                </p>
                                <a href={meta.registrationUrl}>
                                    <button className={classes.button}>
                                        Apply here!
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>

                </>
                )
            }}
        />

    )
}

export default Hero

