import React from 'react'

import { msalConfig, loginRequest } from './../utility/auth/msalConfig';
import { useMsal, MsalProvider } from '../utility/auth/msalContext';


import classes from '../styles/index.module.sass'

import { ChapterItem } from '../components/chapter-item'
import Hero from '../components/hero'
import CourseContentList from '../components/course-content-list'

import Teachers from '../components/teachers'

import { graphql } from 'gatsby'
import Wrapper from '../components/wrapper'
import { renderAst } from '../markdown'


export default ({ data }) => {

    const faq =  data.allMarkdownRemark.edges.filter(
        ({ node }) => node.frontmatter.type === 'faq' && node.frontmatter.title === 'faq home' 
    )[0]

    const faqHtml = renderAst(faq.node.htmlAst)

    const chaptersData =  data.allMarkdownRemark.edges.filter(
        ({ node }) => node.frontmatter.type === 'chapter' 
    )

    const chapters = chaptersData.map( ({node}, i) => {
        const tasks = data.allMarkdownRemark.edges.filter((entry) => entry.node.frontmatter.chapter_id === node.frontmatter.id)   
        return (       
        <ChapterItem type="chapter" id={node.frontmatter.id} tasks={tasks} 
            url={node.fields.slug} 
            keyValue={i} 
            description={node.frontmatter.description}
            title={node.frontmatter.title} 
            index={node.frontmatter.index}>
         </ChapterItem>
        )})

    chapters.sort(function(a, b) {
        if (a.props.index === null || b.props.index === null)
            return true;
        
        return a.props.index - b.props.index;
    });

    const isAuthenticated = useMsal();

    return (
        <MsalProvider
            config={msalConfig}
            scopes={loginRequest}
            >
            <Wrapper>
                { !isAuthenticated && <Hero></Hero> }
                <div className={classes.container}>
                    <h1>Course content</h1>
                </div>

                    <CourseContentList>
                        {chapters}
                    </CourseContentList>
                <Teachers></Teachers>
                <div className={classes.container}>
                    <h1>FAQ</h1>
                    {faqHtml}
                </div>

            </Wrapper>
        </MsalProvider>
        )
}
// <Navigation className={classes.navigation}></Navigation> 

export const pageQuery = graphql`
{
    site {
        siteMetadata {
            title
        }
    }
        allMarkdownRemark {
            edges {
                node {
                    htmlAst
                    frontmatter {
                        title
                        type
                        description    
                        index
                        id        
                        chapter_id               
                    }
                    fields {
                        slug
                        fullPath
                    }
                }
            }
        }
    }
    `